import React from 'react';
import Select from 'react-select';
import './styles.scss';

// Props
const style = {
  menu: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 'none',

    padding: '7px 13px',
    boxShadow: 'none',
    backgroundColor: '#E8E7ED',
    color: '$primary-navy',
    '.dropdown_control': {
      '.dropdown_control__single-value': { fontSize: 14 },
      '&__dropdown-indicator': {
        color: '#170f4f',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      },
    },
  }),
};

// Component
const DropDown = (options) => (
  <Select
    defaultValue={options.defaultValue}
    options={options.options}
    className="dropdown"
    classNamePrefix="dropdown_control"
    styles={style}
    isSearchable={false}
    onChange={options.onSelect}
  />
);

DropDown.defaultProps = {
  defaultValue: {},
  options: [],
  onChange: () => {},
};

export default DropDown;
