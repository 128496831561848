import { find, get } from 'lodash';

const PROTO = {
  /**
   * Extract meta property value from array.
   *
   */
  extractProperty: (metaArr, property, key) => {
    const extractedMeta = find(
      metaArr,
      (eachMeta) => !!(eachMeta.tagName === 'meta' && eachMeta.attributes[property] === key)
    );
    return get(extractedMeta, 'attributes.content');
  },
};

const SeoUtils = Object.create(PROTO);
export default SeoUtils;
